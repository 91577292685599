import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import dynamic from 'next/dynamic';
import Loader from '../loader';

const FontAwesomeIcon = dynamic(() =>
  import('@fortawesome/react-fontawesome').then((mod) => mod.FontAwesomeIcon)
);

function EmptyPlaceholder(props) {
  const { items, message, action, className, showIcon = true } = props;
  const isFetching = useSelector((state) => state.http.isFetching);

  return (
    <div
      className={`w-full bg-transparent text-center flex flex-col items-center ${className}`}
    >
      {isFetching ? (
        <div>
          <Loader size="sm" />
          <p className="mt-2 text-sm font-medium">
            Loading {items}
            ...
          </p>
        </div>
      ) : (
        <div>
          {showIcon && (
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="text-gray-600 mb-2 text-2xl"
              size="xl"
            />
          )}
          {isEmpty(message) && (
            <p className="text-sm font-medium">
              We couldn&apos;t load any {items}.
            </p>
          )}
          {!isEmpty(message) && (
            <p className="text-sm font-medium">{message}</p>
          )}
          {!isEmpty(action) && (
            <div className="mt-3 flex justify-center">{action}</div>
          )}
        </div>
      )}
    </div>
  );
}

EmptyPlaceholder.propTypes = {
  items: PropTypes.string.isRequired,
  message: PropTypes.string,
  action: PropTypes.node,
  className: PropTypes.string,
};

export default EmptyPlaceholder;
