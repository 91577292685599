import React from "react";
import PropTypes from 'prop-types';
import { NextSeo } from 'next-seo';
import Script from 'next/script';
import { GA_TRACKING_ID } from '../lib/gtag';

function CustomHead(props) {
  const {
    title = 'The leading job board for the modern workforce.',
    description = 'The #1 job board for the modern workforce.',
    canonical = 'https://www.elevolt.co.ke/',
    page = '',
    children,
  } = props;


  return (
    <>
      <NextSeo
        title={`${title} | Elevolt`}
        description={`${description} | Elevolt`}
        canonical={canonical + page}
        openGraph={{
          type: 'website',
          url: canonical + page,
          title: `${title} | Elevolt`,
          description: `${description} | Elevolt`,
          images: [
            {
              url: 'https://www.elevolt.co.ke/elevolt-og.png',
              width: 1600,
              height: 800,
              alt: 'Elevolt',
            },
          ],
          site_name: 'Elevolt',
        }}
        facebook={{
          appId: '346824926505117',
        }}
        twitter={{
          handle: '@ElevoltK',
          site: '@ElevoltK',
          cardType: 'summary_large_image',
        }}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      {/* <Script
				id="hotjar"
				strategy="lazyOnload"
				dangerouslySetInnerHTML={{
					__html: `(function(h,o,t,j,a,r){
						h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
						h._hjSettings={hjid:2328489,hjsv:6};
						a=o.getElementsByTagName('head')[0];
						r=o.createElement('script');r.async=1;
						r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
						a.appendChild(r);
					})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
				}}
			/> */}
      {(page.startsWith('job') || page.startsWith('board')) && (
        <Script
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          data-ad-client="ca-pub-4684585494840715"
          crossOrigin=""
          strategy="lazyOnload"
          id="gads"
        />
      )}
      <Script
          id="meta"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '583365066890254');
          fbq('track', 'PageView');`,
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=583365066890254&ev=PageView&noscript=1"
          />
        </noscript>
      {children}
    </>
  );
}

CustomHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  page: PropTypes.string,
  children: PropTypes.node,
};

export default CustomHead;
