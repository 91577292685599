import React from 'react';
import CustomHead from '../../components/head';
import Image from 'next/image';
import { formatDate } from '../../helpers';
import blogImage from '../../public/images/elevolt-blog.png';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import { useRouter } from 'next/router';
import isEmpty from 'lodash.isempty';
import EmptyPlaceholder from '../../components/common/empty-placeholder';
import Button from '../../components/common/button';

const Blog = (props) => {
  const { posts, page = 1, pagination } = props;

  const router = useRouter();

  const handlePagination = (page) => {
    router.push(`/blog?page=${Number(page.selected) + 1}`);
  };

  const handleFilter = (cat) => {
    const url = cat ? `/blog?categories=${cat}` : '/blog';
    router.push(url);
  };

  const clearFilters = () => {
    router.push('/blog');
  };

  const isActiveCategory = (cat) => {
    if (cat === 'all' && !router.query?.categories?.length) {
      return true;
    }

    return router.query?.categories?.includes(cat);
  };

  return <>
    <CustomHead
      title="HR Software for small and medium businesses"
      description="Career and HR resources from Elevolt include valuable blog posts and how-tos for the hiring process."
      page="blog"
    >
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Elevolt',
            url: 'https://www.elevolt.co.ke/blog',
            logo: 'https://www.elevolt.co.ke/images/logo.png',
            sameAs: [
              'https://twitter.com/ElevoltKe',
              'https://www.instagram.com/elevolt.ke/',
              'https://www.facebook.com/elevoltke',
            ],
          }),
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'WebSite',
            name: 'Elevolt',
            url: 'https://www.elevolt.co.ke/blog',
          }),
        }}
      />
    </CustomHead>
    <div className="w-full pt-16 md:pt-24 pb-16 relative flex justify-center items-center overflow-hidden bg-gradient-to-b from-white to-gray-100">
      <div className="container relative hero mx-auto flex flex-col md:justify-center items-start px-4 py-8 md:py-10 bg-cover md:bg-contain bg-center z-20">
        <div className="w-full flex flex-col-reverse md:flex-row items-center">
          <div className="w-full md:w-6/12 md:pr-24">
            <h1 className="text-sm font-semibold text-blue">Elevolt Blog</h1>
            <h2 className="text-2xl font-bold text-blue capitalize mt-4">
              Career and HR insights for the modern professional
            </h2>
            <p className="text-lg font-medium mt-3">
              Nuggets to help you navigate your everyday career and HR
              efforts. Whether you&rsquo;re gearing up for a new position, new
              hire or revamping your talent management, this is your one-stop
              resource.{' '}
            </p>
          </div>
          <div className="w-full md:w-6/12 flex justify-end">
            <div className="w-full max-w-[500px]">
              <Image
                src={blogImage}
                alt="Elevolt Blog"
                className="max-w-md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full">
      <div className="container mx-auto md:pb-10">
        <div className="w-full">
          <h3 className="text-xl font-bold text-blue leading-tight">
            Filter Articles By:
          </h3>
          <ul className="flex mt-3 mb-8">
            <li>
              <button
                onClick={() => handleFilter(null)}
                className={`appearance-none hover:bg-blue-dark hover:text-white px-6 py-2 rounded-full font-medium text-sm ${
                  isActiveCategory('all') ? 'bg-blue text-white' : 'bg-white'
                }`}
              >
                All
              </button>
            </li>
            <li className="ml-3">
              <button
                onClick={() => handleFilter(90)}
                className={`appearance-none hover:bg-blue-dark hover:text-white px-6 py-2 rounded-full font-medium text-sm ${
                  isActiveCategory(90) ? 'bg-blue text-white' : 'bg-white'
                }`}
              >
                Job Seekers
              </button>
            </li>
            <li className="ml-3">
              <button
                onClick={() => handleFilter(91)}
                className={`appearance-none hover:bg-blue-dark hover:text-white px-6 py-2 rounded-full font-medium text-sm ${
                  isActiveCategory(91) ? 'bg-blue text-white' : 'bg-white'
                }`}
              >
                Employers
              </button>
            </li>
          </ul>
        </div>
        {!isEmpty(posts) && (
          <div className="w-full grid md:grid-cols-3 gap-8">
            {posts?.map((post) => (
              (<Link
                key={post.id}
                href={`/blog/${post.slug}`}
                className="bg-white rounded-lg overflow-hidden group">

                <div className="w-full h-[180px] relative bg-blue-dark">
                  <Image
                    src={post.yoast_head_json.og_image[0]['url']}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    className="group-hover:opacity-60"
                    alt={post.yoast_head_json.og_title}
                  />
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-bold text-blue leading-tight">
                    {post.title?.rendered}
                  </h3>
                  <p className="text-sm font-medium mt-1">
                    {formatDate(post.date_gmt, 'PP')}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.excerpt?.rendered,
                    }}
                    className="mt-3 group-hover:text-blue html-text-block"
                  />
                </div>

              </Link>)
            ))}
          </div>
        )}
        {isEmpty(posts) && (
          <div className="w-full bg-white rounded p-4">
            <div className="w-full bg-blue-50 p-8 rounded border border-dashed">
              <EmptyPlaceholder
                items="posts"
                action={
                  <Button design="secondary" onClick={clearFilters}>
                    Clear Filters
                  </Button>
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
    <div className="flex flex-col justify-center items-center py-4 my-8">
      <ReactPaginate
        breakLabel=""
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        onPageChange={handlePagination}
        pageRangeDisplayed={5}
        pageCount={Number(pagination.totalPages)}
        forcePage={Number(page) - 1}
        previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        renderOnZeroPageCount={null}
        marginPagesDisplayed={0}
        className="flex flex-row justify-center items-center text-base text-gray-700 font-normal"
        pageClassName=""
        pageLinkClassName="px-4 py-3 bg-white hover:bg-blue leading-none border-l border-t border-b border-gray-200 hover:border-blue hover:text-white"
        previousLinkClassName="px-4 py-3 bg-white leading-none border-l rounded-l-sm border-t border-b border-gray-200 hover:bg-blue hover:border-blue hover:text-white"
        nextLinkClassName="px-4 py-3 bg-white leading-none border-l border-r rounded-r-sm border-t border-b border-gray-200 hover:bg-blue hover:border-blue hover:text-white"
        activeLinkClassName="!bg-blue hover:bg-blue-dark text-white border-none hover:text-white"
      />
    </div>
  </>;
};

Blog.getInitialProps = async (ctx) => {
  let postsData = [];

  const { page = 1, count = 6, categories } = await ctx.query;

  let url = `https://blog.elevolt.co.ke/wp-json/wp/v2/posts?order=desc&per_page=${count}&page=${page}`;

  if (categories) {
    url += `&categories=${categories}`;
  }

  const res = await fetch(url);

  const posts = await res.json();
  postsData = posts.length ? posts : [];

  return {
    posts: postsData,
    pagination: {
      total: res.headers.get('x-wp-total'),
      totalPages: res.headers.get('x-wp-totalpages'),
    },
    count,
    page,
    categories,
  };
};

export default Blog;
